import { action } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import { htmlSafe } from '@ember/template';
import { isEmpty } from '@ember/utils';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import DS from 'ember-data';

import { initializeOloPay } from '@olo/pay';
import dayjs from 'dayjs';
import { taskFor } from 'ember-concurrency-ts';
import IntlService from 'ember-intl/services/intl';
import { Searcher } from 'fast-fuzzy';

import { Variant } from 'mobile-web/components/button';
import { isForToday } from 'mobile-web/lib/order-criteria';
import isSome from 'mobile-web/lib/utilities/is-some';
import ProductModel from 'mobile-web/models/product';
import Vendor from 'mobile-web/models/vendor';
import { FailureCategory } from 'mobile-web/models/vendor-search-result';
import AnalyticsService, {
  AnalyticsEvents,
  AnalyticsProperties,
} from 'mobile-web/services/analytics';
import BasketService from 'mobile-web/services/basket';
import BusService from 'mobile-web/services/bus';
import ChallengeService from 'mobile-web/services/challenge';
import ChannelService from 'mobile-web/services/channel';
import ContentService, { ContentData } from 'mobile-web/services/content';
import FeaturesService from 'mobile-web/services/features';
import { ProductClickFrom } from 'mobile-web/services/global-data';
import IDService from 'mobile-web/services/id';
import MwcIntl from 'mobile-web/services/mwc-intl';
import OnPremiseService from 'mobile-web/services/on-premise';
import OrderCriteriaService from 'mobile-web/services/order-criteria';
import SessionService from 'mobile-web/services/session';
import StorageService from 'mobile-web/services/storage';
import UserFeedback, { Type } from 'mobile-web/services/user-feedback';
import VendorService from 'mobile-web/services/vendor';

import style from './index.m.scss';

export const BRAND_CAROUSEL_ID = 'olo-brand-carousel';
export const CATEGORY_CAROUSEL_ID = 'olo-category-carousel';

interface Args {
  // Required arguments
  vendor: Vendor;

  // Optional arguments
  showInfoModal?: boolean;
  skipPreCheck?: boolean;
}

interface Signature {
  Args: Args;
}

export default class MenuVendorRoute extends Component<Signature> {
  // Service injections
  @service analytics!: AnalyticsService;
  @service basket!: BasketService;
  @service challenge!: ChallengeService;
  @service channel!: ChannelService;
  @service content!: ContentService;
  @service features!: FeaturesService;
  @service id!: IDService;
  @service intl!: IntlService;
  @service mwcIntl!: MwcIntl;
  @service router!: RouterService;
  @service session!: SessionService;
  @service orderCriteria!: OrderCriteriaService;
  @service storage!: StorageService;
  @service store!: DS.Store;
  @service userFeedback!: UserFeedback;
  @service onPremise!: OnPremiseService;
  @service bus!: BusService;
  @service vendor!: VendorService;

  // Untracked properties
  searchActive = false;
  style = style;
  brandCarouselId = BRAND_CAROUSEL_ID;
  categoryCarouselId = CATEGORY_CAROUSEL_ID;
  brandCarouselResizeObserver = new ResizeObserver(this.setBrandCarouselHeight);

  // Tracked properties
  @tracked brandCarouselHeight = 0;
  @tracked categoryStickyHeight = 0;

  // Getters and setters

  get useMenuSearch() {
    return this.features.flags['ox-new-menu-search-component-48951'];
  }

  get categories() {
    const categories = this.args.vendor.menuCategories;
    const ids = this.id.getFriendlyIDs(categories.map(category => category.name));

    return categories.flatMap((category, index) =>
      category.isHidden
        ? []
        : [
            {
              name: category.name,
              id: ids[index],
              model: category,
            },
          ]
    );
  }

  get products() {
    return this.store.peekAll('product').filter(p => p.vendor === this.args.vendor && p.isVisible);
  }

  get productSearcher() {
    return new Searcher(this.products, { keySelector: obj => obj.name });
  }

  get isMultiConceptVendor(): boolean {
    if (!this.features.flags.VBGK_MENU_AGGREGATION) {
      return false;
    }

    const brandNames = new Set(
      this.args.vendor?.categories.map(category => category.brandName) ?? []
    );
    return brandNames.size > 1;
  }

  get multiConceptBrands() {
    const brandNames = new Set(
      this.args.vendor?.categories.map(category => category.brandName) ?? []
    );
    return this.store.peekAll('brand').filter(b => brandNames.has(b.brandName));
  }

  // make sure the device window has a height larger than 600 pixels to avoid the brand carousel covering the entire screen
  get brandCarouselShouldStick() {
    return (
      window.innerHeight > 600 && this.features.flags['abtest-vbgk-concept-carousel-olo-38237']
    );
  }

  get brandCarouselHeightStyles() {
    return this.isMultiConceptVendor
      ? htmlSafe(`--brand-carousel-height: ${this.brandCarouselHeight}px`)
      : undefined;
  }

  get forYouIsNotShown() {
    return (
      !this.session.isLoggedIn ||
      this.args.vendor.forYouCategories.length === 0 ||
      this.vendor.sortAndFilterRecommendations(
        ProductClickFrom.ForYouCrossSell,
        this.args.vendor.forYouCategories[0].forYou
      ).length === 0
    );
  }

  // Lifecycle methods
  constructor(owner: unknown, args: Args) {
    super(owner, args);

    if (this.args.vendor.settings.isOloPay) {
      initializeOloPay();
    }

    if (this.features.flags.VBGK_MENU_AGGREGATION) {
      this.setBrandSortOrder();
    }
  }

  // Other methods
  async showPreCheckBanner(
    titleCommand: Promise<ContentData>,
    messageCommand: Promise<ContentData>
  ) {
    const changeLocationLabel = this.intl.t('mwc.vendor.changeLocationButton');
    const proceedToMenuLabel = this.intl.t('mwc.vendor.proceedToMenuButton');

    const [titleData, messageData] = await Promise.all([titleCommand, messageCommand]);

    const title = titleData.toString();
    const message = messageData.toString();

    const trackAnalyticsEvent = (locationSelection: string) => {
      const titleTemplate = titleData.toTemplateString();
      const messageTemplate = messageData.toTemplateString();

      this.analytics.trackEvent(AnalyticsEvents.AnsweredChangeLocation, () => ({
        [AnalyticsProperties.AnsweredChangeLocationDialogueText]: `${title} ${message}`,
        [AnalyticsProperties.AnsweredChangeLocationSelection]: locationSelection,
        [AnalyticsProperties.AnsweredChangeLocationTemplateString]: `${titleTemplate} ${messageTemplate}`,
      }));
    };

    this.userFeedback.add({
      type: Type.Negative,
      title,
      message,
      actions: [
        {
          label: changeLocationLabel,
          fn: () => {
            trackAnalyticsEvent(changeLocationLabel);
            this.router.transitionTo('vendor-search-results');
            this.bus.trigger('dismissProductModal');
          },
          variant: Variant.Main,
        },
        {
          label: proceedToMenuLabel,
          fn: () => {
            trackAnalyticsEvent(proceedToMenuLabel);
            this.storage.ignorePrecheckError = true;
          },
          variant: Variant.Minor,
          cancelAction: true,
        },
      ],
    });
  }

  getBusinessHoursMessage(): Promise<ContentData> {
    // Alert the user when the next available hours are if available
    let dateTime: string;

    const timeZone = this.args.vendor.timeZoneId;
    if (timeZone) {
      dateTime = this.mwcIntl.vendorRelativeDateTime(
        new Date(this.args.vendor.nextOpenTime!),
        timeZone,
        { style: 'sentenceCase' }
      );
    } else {
      dateTime = this.mwcIntl.relativeDateTime(dayjs(this.args.vendor.nextOpenTime));
    }

    return this.content.getData(
      'RESP_BUSINESS_HOURS_UNAVAILABLE',
      'mwc.vendor.businessHoursUnavailable',
      {
        vendorName: this.args.vendor.name,
        dateTime,
      }
    );
  }

  /** Sets the sortOrder property on BrandModels based on the order they appear in the categories */
  setBrandSortOrder() {
    let order = 0;
    const brands = this.store.peekAll('brand');
    const categoryBrands = new Set(this.args.vendor.categories.getEach('brandName'));
    categoryBrands.forEach(brandName => {
      brands.findBy('brandName', brandName)?.set('sortOrder', order++);
    });
  }

  // Tasks

  // Actions and helpers
  @action
  setBrandCarouselHeight(entries: ResizeObserverEntry[]) {
    for (const entry of entries) {
      const brandCarousel = entry.target as HTMLElement;
      this.brandCarouselHeight = brandCarousel.offsetHeight;
      break;
    }
  }

  @action
  setCategoryStickyHeight() {
    this.categoryStickyHeight = 0;
    const categorySticky = document.getElementById(CATEGORY_CAROUSEL_ID);
    if (categorySticky) {
      this.categoryStickyHeight = categorySticky!.offsetHeight!;
    }
  }

  @action
  updateCategoryStickyHeight(height: number) {
    this.categoryStickyHeight = height;
  }

  @action
  goToOrderDetails(orderId: string) {
    this.router.transitionTo('order-summary', orderId);
  }

  @action
  onInsert() {
    const scheduledForToday =
      !isSome(this.orderCriteria.criteria) || isForToday(this.orderCriteria.criteria);

    if (this.args.vendor.overrideReason && scheduledForToday) {
      this.showPreCheckBanner(
        this.content.getStringData(''),
        this.content.getStringData(this.args.vendor.overrideReason.reason)
      );
    } else if (!this.args.skipPreCheck) {
      this.preCheck();
    }
  }

  @action
  async preCheck() {
    const shouldDisplayBusinessHoursMsg = !this.args.vendor.isOpen && this.args.vendor.nextOpenTime;

    let criteria = this.orderCriteria.searchOrderCriteria;
    if (this.onPremise.isEnabled) {
      criteria = {
        handoffMode: 'DineIn',
        timeWantedType: 'Immediate',
        searchAddress: '',
      };
    }

    const basket = this.basket.basket;
    if (
      !this.storage.ignorePrecheckError &&
      (!basket || !basket.isAdvance) &&
      !isEmpty(this.args.vendor.unavailableMessage)
    ) {
      if (shouldDisplayBusinessHoursMsg) {
        this.showPreCheckBanner(
          this.content.getTranslationEntry('mwc.vendor.preCheckWarningTitle'),
          this.getBusinessHoursMessage()
        );
      } else {
        this.showPreCheckBanner(
          this.content.getStringData(this.args.vendor.unavailableMessage!),
          this.content.getTranslationEntry('mwc.vendor.unavailableMessage')
        );
      }
    } else if (criteria) {
      // You would think that we could pass `criteria` directly to `preCheck`
      // a few lines down, but TS complains that `criteria` might be undefined.
      // Which is strange, because we're checking `if (criteria)` right here.
      // Even more oddly, simply re-assigning to a new variable is enough to
      // satisfy TS. ¯\_(ツ)_/¯
      const c = criteria;

      taskFor(this.challenge.request).perform(async () => {
        const response = await this.args.vendor.preCheck(c);
        if (!response.isValid && !this.storage.ignorePrecheckError) {
          const isTimeWantedError = response.failureCategory === FailureCategory.TimeWanted;
          this.showPreCheckBanner(
            this.content.getTranslationEntry('mwc.vendor.preCheckWarningTitle'),
            shouldDisplayBusinessHoursMsg && isTimeWantedError
              ? this.getBusinessHoursMessage()
              : this.content.getStringData(response.errorMessage)
          );
        } else if (response.isValid) {
          if (this.basket.basket) {
            this.orderCriteria.updateBasket();
          }
        }
      });
    }
  }

  @action
  onFocusSearch() {
    this.analytics.trackEvent(AnalyticsEvents.MenuSearchBoxIntent);
  }

  @action
  onBlurSearch(searchText: undefined | string, matchingItems: undefined | []) {
    if (searchText && matchingItems?.length) {
      this.analytics.trackEvent(AnalyticsEvents.MenuSearchBoxTyped, () => ({
        [AnalyticsProperties.TextEntered]: searchText,
      }));
    }
  }

  @action
  selectedSearch(searchText: undefined | string, product: ProductModel) {
    if (product.isDisabled) return;

    this.analytics.trackEvent(AnalyticsEvents.MenuSearchBoxSelectedProduct, () => ({
      [AnalyticsProperties.ItemsReturnedInTheSearch]: product.name,
      [AnalyticsProperties.TextEntered]: searchText,
    }));

    this.router.transitionTo('menu.vendor.products', product.vendor.slug, product.id);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Routes::Menu::VendorRoute': typeof MenuVendorRoute;
  }
}
